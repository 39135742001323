import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Ivica = () => {
  return (
    <Layout>
      <Head title="Ivica Capan" />
      <h3>Ivica Capan</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Vienna
      </h4>
      <p>
        <OutboundLink href="https://ello.co/ivicacapan">Ello</OutboundLink>
      </p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/6951365/ello-optimized-81240ab8.jpg"
        alt="Ivica Capan art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15372549/ello-optimized-07917ab0.jpg"
        alt="Ivica Capan art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15345578/ello-optimized-0d71b2be.jpg"
        alt="Ivica Capan art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/9725599/ello-optimized-db19f150.jpg"
        alt="Ivica Capan art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15367739/ello-optimized-9f0afe4e.jpg"
        alt="Ivica Capan art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/4078833/ello-optimized-1fefcfc0.jpg"
        alt="Ivica Capan art"
      />
    </Layout>
  );
};

export default Ivica;
